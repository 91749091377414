import React from "react";
import { Redirect, Route } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import {
  isPlatform,
  setupIonicReact,
  IonApp,
  IonRouterOutlet,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import PresentingElementContext from "./contexts/PresentingElementContext";
import AboutOfflineMode from "./pages/AboutOfflineMode";
import AppUpdate from "./AppUpdate";
import AppLoading from "./AppLoading";
import AppListeners from "./AppListeners";
import DatabaseProvider from "./DatabaseProvider";
import GettingStarted from "./pages/GettingStarted";
import ToastMessage from "./ToastMessage";
import TransientAppError from "./TransientAppError";
import FatalAppError from "./FatalAppError";
import LaunchInspection from "./pages/LaunchInspection";
import Debug from "./pages/Debug";
import Login from "./pages/Login";
import Main from "./pages/Main";
import store from "./store";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Reauthenticator from "./components/Reauthenticator";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/styles.css";

import { registerPrompts } from "./utils/registerPrompts";

setupIonicReact({
  // scrollAssist causes an annoying delay when switching inputs on web:
  // https://github.com/ionic-team/ionic-framework/issues/23664
  scrollAssist: !isPlatform("pwa"),
});

const App = () => {
  const routerRef = React.createRef();
  registerPrompts();

  return (
    <IonApp>
      <ReduxProvider store={store}>
        <DatabaseProvider>
          <Reauthenticator />
          <IonReactRouter>
            <AppListeners />
            <IonRouterOutlet ref={routerRef}>
              <PresentingElementContext.Provider value={routerRef}>
                <Route exact path="/debug" component={Debug} />
                <UnauthenticatedRoute exact path="/login" component={Login} />
                <Route
                  exact
                  path="/help/getting-started"
                  component={GettingStarted}
                />
                <Route
                  exact
                  path="/help/about-offline-mode"
                  component={AboutOfflineMode}
                />
                <AuthenticatedRoute
                  exact
                  path="/launch"
                  component={LaunchInspection}
                />
                <Route path="/main" render={() => <Main />} />
                <Route exact path="/">
                  <Redirect exact from="/" to="/main/home" />
                </Route>
              </PresentingElementContext.Provider>
            </IonRouterOutlet>
          </IonReactRouter>
        </DatabaseProvider>
        <ToastMessage />
        <TransientAppError />
        <FatalAppError />
        <AppUpdate />
        <AppLoading />
      </ReduxProvider>
    </IonApp>
  );
};

export default App;
