import React from "react";
import {
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
} from "@ionic/react";
import { checkmarkDoneCircle, documentText } from "ionicons/icons";
import "./Home.css";

const Home = () => {
  useIonViewDidEnter(() => {
    // Hook point for things we want to do when the home screen loads.
    // For example, check online/offline status and refresh caches.
  });

  return (
    <IonPage className="shaded">
      <IonHeader translucent="true" border="false">
        <IonToolbar>
          <IonTitle>
            <img
              src="/assets/netvendor-mark.png"
              alt="NetVendor"
              width="26"
              height="21"
            />
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen="true">
        <IonListHeader>
          <IonLabel>Inspections</IonLabel>
        </IonListHeader>
        <IonList inset mode="ios">
          <IonItem routerLink="/main/drafts" routerDirection="root">
            <IonIcon icon={documentText} slot="start" color="tertiary" />
            <IonLabel>Drafts</IonLabel>
          </IonItem>
          <IonItem
            lines="none"
            routerLink="/main/inspections"
            routerDirection="root"
          >
            <IonIcon icon={checkmarkDoneCircle} slot="start" color="success" />
            <IonLabel>Submissions</IonLabel>
          </IonItem>
        </IonList>

        <IonListHeader>
          <IonLabel>Help</IonLabel>
        </IonListHeader>
        <IonList inset mode="ios">
          <IonItem routerLink="/help/getting-started" routerDirection="forward">
            <IonLabel>Getting Started</IonLabel>
          </IonItem>
          <IonItem
            lines="none"
            routerLink="/help/about-offline-mode"
            routerDirection="forward"
          >
            <IonLabel>About Offline Mode</IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Home;
