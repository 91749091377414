import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import EmptyStateContainer from "../components/EmptyStateContainer";
import PercentCompleteBadge from "../components/PercentCompleteBadge";
import "./Drafts.css";
import NewInspectionWidget from "../features/inspections/NewInspectionWidget";
import {
  fetchInspections,
  selectDraftInspections,
} from "../features/inspections/inspectionsSlice";
import useDatabase from "../hooks/useDatabase";

const Drafts = () => {
  const db = useDatabase();
  const inspections = useSelector(selectDraftInspections);
  const isLoading = useSelector(
    (state) => state.inspections.status === "loading"
  );
  const user = useSelector((state) => state.user.user);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchInspections({ db, user }));
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Your Inspection Drafts</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {isLoading && <EmptyStateContainer title="Loading..." />}
        {!isLoading && inspections.length === 0 && (
          <EmptyStateContainer title="You have no drafts" />
        )}

        <IonList>
          {inspections.map((inspection, index) => (
            <IonItem
              lines={index + 1 === inspections.length ? "none" : "inset"}
              key={inspection.uuid}
              button
              detail
              routerLink={`/main/inspection/${inspection.uuid}`}
              routerDirection="forward"
            >
              <PercentCompleteBadge
                percentComplete={inspection.percent_complete}
                slot="start"
              />
              <IonLabel>
                <h2>{inspection.unit.name}</h2>
                <h3>{inspection.property.name}</h3>
                <h3>{inspection.template.title}</h3>
                <p>
                  Started:&nbsp;
                  {new Date(inspection.started_at).toLocaleString("en-US")}
                </p>
                {inspection.updated_at && (
                  <p>
                    Last Updated:&nbsp;
                    {new Date(inspection.updated_at).toLocaleString("en-US")}
                  </p>
                )}
              </IonLabel>
            </IonItem>
          ))}
        </IonList>
        <NewInspectionWidget />
      </IonContent>
    </IonPage>
  );
};

export default Drafts;
