import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonLabel,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { arrowForwardOutline } from "ionicons/icons";
import {
  generateDraftInspections,
  inspectionDismissed,
} from "./newInspectionSlice";
import { displayErrorMessage } from "../appSlice";
import PropertySelector from "../properties/PropertySelector";
import TemplateSelector from "../templates/TemplateSelector";
import UnitSelector from "../units/UnitSelector";
import useDatabase from "../../hooks/useDatabase";

const NewInspectionPage = () => {
  const db = useDatabase();
  const history = useHistory();
  const dispatch = useDispatch();
  const isPreparing = useSelector(
    (state) => state.newInspection.status === "preparing"
  );
  const selectedUnits = useSelector(
    (state) => state.newInspection.selectedUnits
  );
  const selectedProperty = useSelector(
    (state) => state.newInspection.selectedProperty
  );
  const selectedTemplate = useSelector(
    (state) => state.newInspection.selectedTemplate
  );
  const user = useSelector((state) => state.user.user);

  const cancel = () => {
    dispatch(inspectionDismissed());
    history.push("/main/drafts");
  };

  const handleStart = () => {
    dispatch(generateDraftInspections({ db, user })).then(
      ({ meta: { requestStatus }, payload, error }) => {
        if (requestStatus === "fulfilled") {
          dispatch(inspectionDismissed());
          history.push(`/main/inspection/${payload[0].uuid}`);
        } else {
          console.log("error", error); // eslint-disable-line no-console
          dispatch(displayErrorMessage(error.message));
        }
      }
    );
  };

  const selectedUnitCountTitle = () => {
    if (selectedUnits.length === 0) {
      return "No units selected";
    }
    if (selectedUnits.length === 1) {
      return "1 unit selected";
    }
    return `${selectedUnits.length} units selected`;
  };

  return (
    <IonPage className="page-new-inspection">
      <IonHeader>
        <IonToolbar>
          <IonTitle>New Inspection</IonTitle>
          <IonButtons slot="start">
            <IonButton onClick={cancel}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {isPreparing && <IonLoading isOpen />}
        <PropertySelector selectedProperty={selectedProperty} />
        {selectedProperty && (
          <TemplateSelector selectedTemplate={selectedTemplate} />
        )}
        {selectedTemplate && (
          <UnitSelector
            propertyId={selectedProperty.id}
            scope={selectedTemplate.scope}
          />
        )}
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonLabel slot="start">{selectedUnitCountTitle()}</IonLabel>
          <IonButtons slot="end">
            <IonButton
              disabled={selectedUnits.length === 0}
              fill="solid"
              onClick={handleStart}
            >
              Start
              <IonIcon slot="end" icon={arrowForwardOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default NewInspectionPage;
